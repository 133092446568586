.CardContainer {
  display: flex;
  align-items: center;
  gap: 30px;

  img {
    width: 340px;
    height: 225px;
    border-radius: 12px;
    object-fit: cover;
  }

  .InfoCardContainer {
    padding-top: 14px;
    padding-bottom: 14px;

    .Title {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #0A1221;
    }

    .Description {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      color: #0E0E0F;
    }

    .TimeToRead {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: 0.05em;
      color: #775D00;
      gap: 22px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
      height: auto;
      max-width: 340px;
    }

    .InfoCardContainer {
      max-width: 340px;

      .Title {
        font-size: 20px;
        line-height: 24px;
      }

      .Description {
        font-size: 16px;
      }

      .TimeToRead {
        font-size: 14px;
      }
    }
  }
}
