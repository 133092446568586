.Container {
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 70px;
  min-height: 550px;
  // background: linear-gradient(116.4deg, #0A1221 7.68%, #0E0E0F 80.05%);
  background: linear-gradient(116.4deg, #601ee7 7.68%, #0378ff 80.05%);

  .Subtitle {
    // font-family: 'Parisienne';
    font-size: 35px;
    color: #C3BE70;
  }

  .Title {
    font-weight: 700;
    font-size: 65px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
  }

  .PartnersContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 20px;
    gap: 10px;

    .Partner {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 55px;
      max-height: 212px;
      min-height: 212px;
      max-width: 212px;
      min-width: 212px;
      position: relative;

      span {
        position: absolute;
        height: 52px;
        width: 52px;
        border: 1px solid rgba(119, 93, 0, 1)
      }

      .TopLeft {
        top: 0px;
        left: 0px;
        border-width: 1px 0px 0px 1px;
      }

      .TopRight {
        top: 0px;
        right: 0px;
        border-width: 1px 1px 0px 0px;
      }

      .BottomLeft {
        bottom: 0px;
        left: 0px;
        border-width: 0px 0px 1px 1px;
      }

      .BottomRight {
        bottom: 0px;
        right: 0px;
        border-width: 0px 1px 1px 0px;
      }
    }

    .Partner:not(:first-child) {
      max-height: 192px;
      min-height: 192px;
      max-width: 192px;
      min-width: 192px;
    }
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
    min-height: 400px;

    .Subtitle {
      font-size: 24px;
    }

    .Title {
      font-size: 30px;
    }

    .PartnersContainer {
      justify-content: center;

      .Partner {
        margin: 30px;
        height: 120px;
        width: 120px;
      }
    }
  }
}
