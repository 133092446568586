.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  img {
    width: 100%;
  }

  @media (max-width: 1100px) {
    height: auto;

    .MemberContainer {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 0 20px;
      gap: 30px;
    }
  }

  @media (max-width: 768px) {
    height: auto;

    .SubTitle {
      margin-top: 30px;
      font-size: 24px;
    }

    .Title {
      margin-top: 20px;
      font-size: 30px;
      text-align: center;
    }

    .MemberContainer {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;
      gap: 30px;
    }
  }
}
