.languageSwitch {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.select {
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  background-color: #715800;
  color: white;
  cursor: pointer;
  outline: none;

  option:hover {
    background-color: #715800;
  }
}
