.Card {
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 400px;
  background: linear-gradient(286.98deg, #0A1221 8.33%, #0E0E0F 95.12%);
  border-radius: 50px 50px 0px 0px;
  border: none;
  overflow: hidden;

  .CardImg {
    width: 100%;
    height: 60%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .CardDescription {
    padding: 0 20px;
    width: 100%;
    position: relative;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FCF9CE;

    .Title {
      color: #D9D9D9;
      font-family: Lobster;
      font-weight: 400;
      font-size: 20px;
      line-height: 105%;
      text-transform: none;
    }

    .LogoShield {
      position: absolute;
      top: -75px;
      right: 0;
    }
  }

  @media (max-width: 1280px) {
    width: 285px;
    height: 300px;

    .CardImg {
      margin-bottom: 15px;
    }

    .CardDescription {

      .Title {
        font-size: 16px;
      }

      .LogoShield {
        top: -70px;
      }

      div {
        font-size: 16px;
      }
    }
  }
}




