.ServicesTopContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .InnerContainer {
    position: relative;
    height: 212px;

    .ServicesTop {
      margin-top: 35px;
      margin-left: 8%;

      .Location {
        display: flex;
        gap: 6px;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: #FFFFFF;

        a {
          text-decoration: none;
          color: #FFFFFF;
        }

        span {
          display: flex;
          align-items: center;
          color: #715800;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.05em;
        }
      }

      .Title {
        margin-top: 8px;
        font-weight: 500;
        font-size: 65px;
        line-height: 112%;
        letter-spacing: 0.02em;
        color: #FFFFFF;
      }
    }
  }

  @media (max-width: 768px) {
    .InnerContainer {
      .ServicesTop {
        margin-top: 0;

        .Title {
          font-size: 30px;
        }
      }
    }
  }
}
