* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Space Grotesk', sans-serif;
}
