.Container {
  padding: 120px;
  height: 970px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(213.95deg, #775D00 23.94%, #FFFDDC 97.76%);
    opacity: 0.1;
    z-index: -1;
  }

  .Subtitle {
    font-weight: 400;
    font-size: 35px;
    color: #715800;
    font-family: Lobster;
  }

  .Title {
    margin-bottom: 55px;
    font-weight: 700;
    font-size: 65px;
    color: #0A1221;
    line-height: 105%;
  }

  .List {
    width: 80%;
    margin-left: auto;

    .Item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 90px;
      position: relative;

      a {
        display: flex;
        gap: 45px;
        align-items: center;
        font-weight: 500;
        font-size: 25px;
        letter-spacing: -0.02em;
        color: #0A1221;
        text-decoration: none;
        opacity: 0.9;

        span {
          font-size: 16px;
          font-weight: 700;
          opacity: 0.2;
        }
      }

      div {
        display: flex;
        align-items: center;
        position: relative;
      }

      svg {
        position: absolute;
        left: -50%;
        transform: translateX(-50%);
      }

      .ArrowToRight {
        position: relative;
        left: 0;
        margin-right: 50px;
      }

      .Divider {
        position: absolute;
        bottom: 0;
        width: 105%;
        height: 1px;
        opacity: 0.2;
        background: #0A1221;
      }
    }

    .Active {
      a {
        font-size: 30px;
        color: #775D00;

        span {
          color: #0A1221;
          font-size: 22px;
          opacity: 1;
        }
      }

      .Divider {
        position: absolute;
        bottom: 0;
        width: 105%;
        height: 2px;
        background: #775D00;
        opacity: 1;
      }
    }
  }

  .HomeConnect {
    position: absolute;
    left: 0;
    bottom: -270px;
  }

  @media (max-width: 1100px) {
    .Subtitle {
      font-size: 28px;
    }

    .Title {
      font-size: 45px;
    }

    .List {
      .Item {
        a {
          font-size: 20px;
          gap: 30px;

          span {
            font-size: 14px;
          }
        }

        .Divider {
          width: 100%;
          height: 1px;
        }
      }

      .Active {
        a {
          font-size: 25px;
        }

        .Divider {
          height: 2px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 20px;
    height: auto;

    .Subtitle {
      font-size: 24px;
    }

    .Title {
      font-size: 30px;
    }

    .List {
      width: 100%;

      .Item {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;

        a {
          font-size: 16px;
          gap: 30px;

          span {
            font-size: 14px;
          }
        }

        div {
          position: static;
        }

        .ArrowToRight {
          display: none;
        }

        .Divider {
          width: 100%;
          height: 1px;
        }
      }

      .Active {
        a {
          font-size: 16px;
        }

        .Divider {
          height: 2px;
        }
      }
    }

    .HomeConnect {
      display: none;
    }
  }
}
