.Container {
  position: relative;
  min-height: 100vh;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(213.95deg, #775D00 23.94%, #FFFDDC 97.76%);
    opacity: 0.1;
    z-index: -1;
  }

  .InnerContainer {
    display: flex;
    width: 1200px;
    margin: auto;
    padding-top: 75px;
    padding-bottom: 83px;
    gap: 80px;

    .LeftContainer {
      display: flex;
      flex-direction: column;
      width: 840px;
      gap: 60px;

      a {
        text-decoration: none;
      }

      .HighlightContainer {
        display: flex;
        flex-direction: column;

        .Highlight {
          font-weight: 500;
          font-size: 27px;
          line-height: 34px;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #715800;
        }

        .Title {
          margin-top: 10px;
          font-weight: 700;
          font-size: 45px;
          line-height: 105%;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #0A1221;
        }

        .Subtitle {
          margin-top: 10px;
          font-family: Lobster;
          font-weight: 400;
          font-size: 19px;
          line-height: 130%;
          color: #0E0E0F;
          opacity: 0.7;
        }

        .Image {
          margin-bottom: 28px;
          margin-top: 28px;
          width: 840px;
          height: 358px;
          border-radius: 20px;
          object-fit: cover;
        }

        .Description {
          font-weight: 400;
          font-size: 19px;
          line-height: 130%;
          color: #0E0E0F;
        }

        .TimeToRead {
          margin-top: 12px;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: 0.05em;
          color: #775D00;
          gap: 35px;
        }
      }

      .CardContainer {
        display: flex;
        flex-direction: column;
        gap: 34px;
        position: relative;

        a {
          text-decoration: none;
        }

        .Divider {
          position: absolute;
          bottom: -30px;
          width: 100%;
          height: 1px;
          background: linear-gradient(217.98deg, #715800 -29.02%, #FCF9CE 123.25%);
        }

        .Divider1 {
          position: absolute;
          top: -30px;
          width: 100%;
          height: 1px;
          background: linear-gradient(217.98deg, #715800 -29.02%, #FCF9CE 123.25%);
        }
      }

      .SelectContainer {
        display: flex;
        justify-content: space-between;

        .SelectItem {
          display: flex;
          align-items: center;
          gap: 10px;
          background: transparent;
          border: none;
          font-weight: 500;
          font-size: 27px;
          line-height: 34px;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #715800;
          cursor: pointer;

          svg {
            transform: rotate(180deg);
          }
        }

        .SelectItem1 {
          display: flex;
          align-items: center;
          gap: 10px;
          background: transparent;
          border: none;
          font-weight: 500;
          font-size: 27px;
          line-height: 34px;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #715800;
          cursor: pointer;
        }
      }
    }

    .RightContainer {
      .TopCardContainer {
        display: flex;
        flex-direction: column;
        gap: 28px;

        .ToBlog {
          width: 280px;
          height: 290px;
          position: relative;
          background: #f1eee6;
          border: none;
          border-radius: 18px;
          cursor: pointer;
          text-decoration: none;
          z-index: 1;

          &::before {
            content: "";
            position: absolute;
            top: -3px;
            left: -3px;
            right: -3px;
            bottom: -3px;
            background: linear-gradient(217.98deg, #715800 -29.02%, #FCF9CE 123.25%);
            z-index: -1;
            border-radius: inherit;
          }

          &::after {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            background: inherit;
            z-index: -1;
            border-radius: inherit;
          }
        }
      }

      .ArchiveContainer {
        display: flex;
        flex-direction: column;
        margin-top: 48px;

        .Archive {
          height: 50px;
          width: 280px;
          position: relative;
          font-weight: 500;
          font-size: 27px;
          line-height: 34px;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #0A1221;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(217.98deg, #715800 -29.02%, #FCF9CE 123.25%);
          }
        }

        .ArchiveItem {
          display: flex;
          align-items: center;
          height: 50px;
          width: 280px;
          font-weight: 400;
          font-size: 19px;
          line-height: 130%;
          color: #0E0E0F;
          border: none;
          border-bottom: 1px solid rgba(10, 18, 33, 0.2);
          background-color: transparent;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .InnerContainer {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

      flex-direction: column;
      align-items: center;

      .LeftContainer,
      .RightContainer {
        width: 100%;
        max-width: 600px;
      }

      .LeftContainer {
        padding-top: 30px;

        .HighlightContainer {
          .Image {
            width: 100%;
            height: auto;
          }
        }
      }

      .RightContainer {
        .TopCardContainer {
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
        }

        .ArchiveContainer {
          .Archive,
          .ArchiveItem {
            width: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .InnerContainer {
      .LeftContainer {
        .HighlightContainer {
          .Title {
            font-size: 30px;
          }

          .Highlight {
            font-size: 24px;
          }

          .Description {
            font-size: 16px;
          }

          .TimeToRead {
            font-size: 14px;
          }
        }

        .SelectContainer {
          .SelectItem {
            font-size: 18px;
          }

          .SelectItem1 {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #333;
  padding: 20px;
}

.LoadingLine {
  width: 80px;
  height: 2px;
  background: #333;
  animation: loading 1s infinite alternate;
}

@keyframes loading {
  0% {
    width: 80px;
  }
  100% {
    width: 40px;
  }
}
