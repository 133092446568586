.CardsContainer {
  min-height: 590px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(213.95deg, rgba(119, 93, 0, 0.1) 23.94%, rgba(255, 253, 220, 0.1) 97.76%);
    opacity: 0.1;
    z-index: -1;
  }

  .InnerContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    padding-top: 32px;
    padding-bottom: 60px;
    margin-left: 8%;
    margin-right: 8%;

    a {
      text-decoration: none;
    }

    @media (max-width: 1100px) {
      justify-content: center;
    }
  }

  .Title {
    margin-left: 8%;
    padding-top: 50px;
    font-family: Lobster;
    font-weight: 400;
    font-size: 35px;
    line-height: 48px;
    color: #715800;
  }
}
