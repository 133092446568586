.ServicesTopContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .InnerContainer {
    position: relative;
    height: 496px;
    overflow: hidden;

    .ServicesTop {
      display: flex;
      justify-content: space-between;
      margin-left: 8%;
      position: relative;
      z-index: 1;

      .ServicesInnerContainer {
        .TextContainer {
          display: flex;
          margin-top: 96px;

          .TextLeftContainer {
            margin-right: 142px;
          }

          .Title {
            margin-bottom: 28px;
            font-family: Lobster;
            font-size: 35px;
            line-height: 48px;
            color: #C3BE70;
          }

          .Info {
            display: flex;
            align-items: center;
            margin-bottom: 18px;
            color: #FFFFFF;
            font-weight: 400;
            font-size: 20px;
            line-height: 105%;
            letter-spacing: -0.02em;

            svg {
              margin-right: 14px;
              g {
                path {
                  fill: #545861;
                }
              }
            }
          }
        }

        .IconContainer {
          display: flex;
          align-items: center;
          gap: 24px;
          margin-top: 50px;

          svg {
            cursor: pointer;
          }
        }
      }

      .ImageContainer {
        margin-right: 2%;
      }
    }

    .ScheduleDeco {
      position: absolute;
      left: -699px;
      top: 145px;
      height: 703px;
    }
  }

  @media (max-width: 1280px) {
    .InnerContainer {
      .ServicesTop {
        gap: 20px;
        .ServicesInnerContainer {
          .TextContainer {
            .TextLeftContainer {
              margin-right: 50px;
            }
          }
        }

        .ImageContainer {
          img {
            width: 400px;
          }
        }
      }
    }
  }

  @media (max-width: 920px) {
    .InnerContainer {
      .ServicesTop {
        flex-direction: column;
        margin-left: 0;

        .ServicesInnerContainer {
          .TextContainer {
            flex-direction: column;
            margin-top: 0;
            gap: 40px;

            .TextLeftContainer {
              margin-right: 0;
            }

            .Title {
              text-align: center;
              margin-bottom: 20px;
            }

            .Info {
              justify-content: center;
              margin-bottom: 12px;
            }
          }

          .IconContainer {
            justify-content: center;
            margin-top: 20px;
          }
        }

        .ImageContainer {
          display: none;
        }
      }

      .ScheduleDeco {
        bottom: 0;
      }
    }
  }
}
