.Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 120px;
  min-height: 674px;
  position: relative;
  gap: 115px;

  @media (max-width: 1280px) {
    min-height: 500px;
    padding-left: 30px;
    justify-content: center;
    text-align: center;
    gap: 50px;

    .Image {
      height: 400px;
    }

    .TextContainer {
      max-width: 100%;
    }

    .Subtitle {
      font-size: 25px;
    }

    .Title {
      font-size: 45px;
    }

    .Paragraph,
    .SecondParagraph {
      font-size: 16px;
    }

    .SecondParagraph {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 768px) {
    padding-left: 30px;
    margin-bottom: 5%;
    justify-content: center;
    text-align: center;
    gap: 50px;

    .Image {
      display: none;
    }

    .TextContainer {
      max-width: 100%;
    }

    .Subtitle {
      font-size: 24px;
    }

    .Title {
      font-size: 30px;
    }

    .Paragraph,
    .SecondParagraph {
      font-size: 16px;
      text-align: left;
    }
  }
}

.TextContainer {
  flex: 1;
  max-width: 45%;
}

.Subtitle {
  margin-top: 5%;
  font-family: Lobster;
  font-weight: 400;
  font-size: 35px;
  color: #715800;
}

.Title {
  font-weight: 700;
  font-size: 65px;
  letter-spacing: -0.02em;
  color: #0A1221;
}

.Paragraph,
.SecondParagraph {
  margin-top: 2rem;
  font-weight: 400;
  font-size: 19px;
  color: #0E0E0F;
  max-width: 100%;
}

.SecondParagraph {
  margin-top: 19px;
  color: #715800;
}

.Image {
  max-height: 582px;
  width: 100%;
  height: auto;
  object-fit: contain;
}


