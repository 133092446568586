.InfoContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 8%;
  min-height: 700px;
  position: relative;

  .TextContainer {
    margin-top: 75px;
    width: 50%;
    font-weight: 400;
    font-size: 19px;
    line-height: 130%;
    color: #0E0E0F;

    .Title {
      margin-bottom: 42px;
      font-weight: 700;
      font-size: 45px;
      line-height: 105%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #0A1221;
    }

    .BottomText {
      color: #715800;
      margin-bottom: 30px;
    }
  }

  .ImageContainer {
    margin-top: 60px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(213.95deg, #775D00 23.94%, #FFFDDC 97.76%);
    opacity: 0.1;
    z-index: -1;
  }

  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
    padding-right: 8%;
    min-height: 0;

    .TextContainer {
      width: 100%;
      margin-top: 30px;
      text-align: center;
      font-size: 16px;
    }

    .Title {
      font-size: 35px;
      margin-bottom: 30px;
    }

    .ImageContainer {
      display: none;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 0;

    .TextContainer {
      width: 100%;
      margin-top: 30px;
      text-align: left;

      .Title {
        font-size: 30px;
        margin-bottom: 30px;
      }
    }

    .ImageContainer {
      margin-top: 30px;
    }
  }
}

.Container {
  padding-top: 45px;
  height: 700px;
  position: relative;

  .Subtitle {
    margin-left: 8%;
    font-weight: 400;
    font-size: 35px;
    color: #715800;
    font-family: Lobster;
  }

  .List {
    width: 1043px;
    margin: auto;

    .Item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 90px;
      position: relative;

      a {
        display: flex;
        gap: 45px;
        align-items: center;
        font-weight: 500;
        font-size: 25px;
        letter-spacing: -0.02em;
        color: #0A1221;
        text-decoration: none;
        opacity: 0.9;

        span {
          font-size: 16px;
          font-weight: 700;
          opacity: 0.2;
        }
      }

      div {
        display: flex;
        align-items: center;
      }

      .Divider {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        opacity: 0.2;
        background: #0A1221;
      }
    }

    .Active {
      div {
        font-size: 30px;
        color: #775D00;

        span {
          color: #0A1221;
          font-size: 22px;
          opacity: 1;
        }
      }

      .Divider {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #775D00;
        opacity: 1;
      }
    }
  }

  .HomeConnect {
    position: absolute;
    left: 0;
    bottom: -270px;
  }

  .ServicesDeco {
    position: absolute;
    top: -275px;
    z-index: -1;
  }

  @media (max-width: 1100px) {
    .Subtitle {
      font-size: 28px;
    }

    .List {
      .Item {
        a {
          font-size: 20px;
          gap: 30px;

          span {
            font-size: 14px;
          }
        }

        svg {
          display: none;
        }
      }

      .Active {
        a {
          font-size: 25px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    height: auto;

    .Subtitle {
      margin-left: 20px;
      font-size: 25px;
    }

    .List {
      width: 100%;
      max-width: 500px;
      margin: auto;

      .Item {
        height: 70px;

        a {
          font-size: 16px;

          span {
            font-size: 14px;
          }
        }

        svg {
          display: none;
        }
      }

      .Active {
        div {
          font-size: 16px;
        }
      }
    }

    .HomeConnect {
      display: none;
    }

    .ServicesDeco {
      display: none;
    }
  }
}
