.Container {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(213.95deg, rgba(119, 93, 0, 0.1) 23.94%, rgba(255, 253, 220, 0.1) 97.76%);
    z-index: -1;
  }

  .TextContainer {
    .TopContainer {
      display: flex;
      justify-content: center;
      padding-top: 75px;
      margin: auto;
      width: 1440px;
      gap: 171px;

      .ItemContainer {
        width: 100%;
        flex-shrink: 0;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .ImageContainer {
        position: relative;
        padding-top: 41px;

        @media (max-width: 1356px) {
          width: 100%;
          padding: 0;
        }

        img {
          width: 458px;
          height: 600px;
          border-radius: 50px;
          position: relative;
          object-fit: cover;

          @media (max-width: 1356px) {
            width: 100%;
          }

          @media (max-width: 768px) {
            width: 100%;
            height: auto;
          }
        }

        &::before {
          content: "";
          position: absolute;
          width: 458px;
          height: 600px;
          left: 45px;
          top: 0px;
          background: linear-gradient(224.37deg, #775D00 -16.75%, #C4BE70 107.77%);
          border-radius: 50px;
        }

        svg {
          position: absolute;
          left: -107px;
          top: 105px;
        }
      }

    }

    .BottomContainer {
      display: flex;
      align-items: center;
      margin: auto;
      width: 1440px;
      gap: 100px;

      svg {
        opacity: 0.2;
      }

      .ItemContainer {
        width: 721px;
      }
    }

    .TickContainer {
      display: flex;
      margin-bottom: 18px;
      gap: 20px;

      svg {
        height: 24px;
        width: 24px;
        opacity: 1;
      }
    }

    .NumContainer {
      display: flex;
      margin-bottom: 18px;
      margin-top: 18px;
      gap: 20px;

      .NumHead {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #C0B762;
        font-weight: 500;
        font-size: 15px;
        color: #FFFFFF;
        flex-shrink: 0;
      }
    }

    .Title {
      margin-bottom: 28px;
      font-weight: 700;
      font-size: 45px;
      line-height: 105%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #0A1221;
    }

    div {
      font-weight: 400;
      font-size: 19px;
      line-height: 25px;
      color: #0E0E0F;
    }

    .Item {
      margin-top: 28px;
      margin-bottom: 28px;
      font-weight: 500;
      font-size: 27px;
      line-height: 34px;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #715800;
    }
  }
}


@media (max-width: 1600px) {
  .Container .TextContainer .TopContainer {
    width: 1200px;
    gap: 100px;
  }
}

@media (max-width: 1380px) {
  .Container .TextContainer .TopContainer {
    width: 720px;
    flex-wrap: wrap;
    gap: 40px;
  }

  .Container .TextContainer .ItemContainer {
    width: 100%;
  }

  .Container .TextContainer .ImageContainer {
    margin-top: 40px;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      display: none;
    }

    .Shield1 {
      display: none;
    }
  }

  .Container .TextContainer .BottomContainer {
    width: 720px;
    gap: 40px;

    .CaseDetailShield {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .Container .TextContainer .TopContainer {
    width: 100%;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    gap: 30px;
    padding-top: 40px;

    .ImageContainer {
      width: 100%;
    }
  }

  .Container .TextContainer .ImageContainer {
    margin-top: 0;
    padding-top: 0;

    img {
      width: 100%;
      height: auto;
      border-radius: 0;
    }
  }

  .Container .TextContainer .BottomContainer {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
    gap: 30px;
  }

  .Container .TextContainer .ItemContainer {
    width: 100%;

    .Title {
      font-size: 30px;
    }

    .Item {
      font-size: 24px;
    }

    div {
      font-size: 16px;
    }
  }
}
