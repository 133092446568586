.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 390px;
  position: relative;
  // background: linear-gradient(279.17deg, #0A1221 0.9%, #0E0E0F 45.61%, #0A1221 86.11%);
  background: linear-gradient(116.4deg, #3f34f0 7.68%, #0378ff 80.05%);
  overflow: hidden;

  .SubTitle {
    color: rgba(195, 190, 112, 1);
    font-size: 35px;
    font-family: Lobster;
  }

  .Title {
    color: #FFFFFF;
    font-size: 65px;
    font-weight: 500;
    letter-spacing: -0.02em;
  }

  .Button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 30px;
    margin-top: 32px;
    width: 263px;
    height: 50px;
    position: relative;
    gap: 10px;
    background: #0E0E0F;
    // background: #1b5cf9;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      background: linear-gradient(116.4deg, #3f34f0 7.68%, #0378ff 80.05%);
      z-index: -1;
      border-radius: inherit;
    }

    &::after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: inherit;
      z-index: -1;
      border-radius: inherit;
    }

    span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #FCF9CE;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.03em;
    }
  }

  .ScheduleDeco1 {
    position: absolute;
    left: -724px;
    top: -25%;
  }

  .ScheduleDeco2 {
    position: absolute;
    transform: rotate(90deg);
    right: -724px;
    bottom: -25%;
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 20px;

    .SubTitle {
      font-size: 24px;
    }

    .Title {
      font-size: 30px;
      text-align: center;
    }

    .Button {
      width: 220px;
      height: 40px;
      padding: 12px 25px;

      span {
        font-size: 16px;
      }
    }

    .ScheduleDeco1,
    .ScheduleDeco2 {
      display: none;
    }
  }
}
