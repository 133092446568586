.CardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px;
  gap: 12px;

  img {
    width: 252px;
    height: 162px;
    border-radius: 12px;
  }

  .InfoCardContainer {
    .Title {
      font-weight: 700;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #0A1221;
    }
  }
}
