.CardsContainer {
  min-height: 1038px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(213.95deg, #775D00 23.94%, #FFFDDC 97.76%);
    opacity: 0.1;
    z-index: -1;
  }

  .InnerContainer {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    grid-gap: 20px;
    padding: 0px 120px;
    padding-top: 100px;
    padding-bottom: 110px;
    margin: auto;
    max-width: 1500px;

    a {
      text-decoration: none;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 1280px) {
    min-height: 500px;
    .InnerContainer {
      grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
      padding: 40px 0;
      margin: auto;
    }
  }
}
