.HeaderContainer {
  // background: linear-gradient(116.4deg, #0A1221 7.68%, #0E0E0F 80.05%);
  background: linear-gradient(116.4deg, #601ee7 7.68%, #0378ff 80.05%);
  min-height: 0px;
  transition: height 0.3s;
  overflow: hidden;

  .Sticky {
    width: 100%;
    position: fixed;
    top: 0;
    // background: linear-gradient(116.4deg, #0A1221 7.68%, #0E0E0F 80.05%);
    background: linear-gradient(116.4deg, #601ee7 7.68%, #0378ff 80.05%);
    z-index: 2;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
    height: 140px;
    position: relative;
    z-index: 2;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .Logo {
      z-index: 1;

      a {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 80px;
          // width: 80px;

          @media (max-width: 768px) {
            height: 60px;
            // width: 60px;
          }
        }
      }
    }

    .MobileMenuIcon {
      display: none;
      cursor: pointer;
      background: transparent;
      border: none;
    }

    .MobileMenuIcon .Bar {
      width: 30px;
      height: 3px;
      background-color: rgb(252, 249, 206);
      margin: 6px 0;
      transition: transform 0.3s ease-in-out;
    }

    .MobileMenuIcon.Open .Bar:first-child {
      transform: translateY(10px) rotate(45deg);
    }

    .MobileMenuIcon.Open .Bar:nth-child(2) {
      opacity: 0;
    }

    .MobileMenuIcon.Open .Bar:last-child {
      transform: translateY(-10px) rotate(-45deg);
    }

    .MobileWrapper {
      display: flex;
      justify-content: flex-end;
      width: 100vw;
      height: 100vh;

      position: fixed;
      top: 0;
      left: 0;

      visibility: hidden;
      opacity: 0;

      transition: all .3s ease;

      .Overlay {
        display: block;
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        background-color: #0000009d;
      }

      .Menu {
        display: flex;
        flex-direction: column;
        width: 60%;
        max-width: 430px;
        min-width: 250px;
        height: 100%;
        overflow: auto;
        // background-color: #0E0E0F;
        background-color: #1b5cf9;
        transform: translateX(100%);
        transition: all .3s ease;
        z-index: 3;

        > *:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        ul {
          padding-left: 16px;
          flex-direction: column;
          gap: 6px;

          li {
            padding: 8px 16px;
            a {
              position: relative;
              padding: 0;

              svg {
                margin-top: 0;
                margin-bottom: 0;
                height: 20px;
              }
            }
          }
        }

        svg {
          margin-top: 80px;
          margin-bottom: 40px;
          align-self: center;
          width: 50%;
          height: auto;
        }

        .Dropdown {
          position: relative;

          &.ShowDropdown {
            background-color: transparent;

            .DropdownMenu {
              display: block;
            }
          }

          .DropdownMenu {
            width: 175px;
          }
        }
      }
    }

    .MobileNav {
      visibility: initial;
      opacity: 1;

      .Menu {
        transform: translateX(0);
      }
    }

    @media (max-width: 1100px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: 1100px) {
      height: 80px;

      .MobileMenuIcon {
        display: block;
      }
    }

    .NavWrapper {
      @media (max-width: 1100px) {
        gap: 10px;
      }

      @media (max-width: 1100px) {
        display: none;
      }
    }


    nav {
      position: relative;
      z-index: 1;
      ul {
        display: flex;
        list-style-type: none;
        gap: 12px;

        li {
          position: relative;
          padding: 12px 16px;

          a {
            text-decoration: none;
            color: rgba(252, 249, 206, 1);
            font-size: 16px;
            letter-spacing: 0.05em;
          }

          .Ellipse {
            position: absolute;
            opacity: 0;
            left: 20px;
            right: auto;
            transition: left 0.3s, right 0.3s, opacity 0.3s;
            z-index: -1;
          }
        }

        .ResourceButton {
          padding: 0;

          a {
            padding: 12px 16px;
          }
        }

        .Active {
          .Ellipse {
            opacity: 1;
            left: calc(100% - 20px);
            transition: left 0.3s, right 0.3s, opacity 0.3s;
          }

          a {
            color: #FFFFFF;
          }
        }

        .Dropdown {
          position: relative;

          &.ShowDropdown {
            // background-color: rgba(119, 93, 0, 1);

            .DropdownMenu {
              display: block;

              li {
                &:hover {
                  background: rgba(0, 0, 0, 0.25);
                }

                a {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
              }
            }
          }

          .DropdownMenu {
            width: 175px;
            position: absolute;
            left: 0;
            top: 45px;
            padding: 0px;
            background-color: rgba(119, 93, 0, 1);
            display: none;

            li {
              margin-bottom: 5px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  .Container {
    position: relative;

    .ShieldLowerOpacity {
      position: absolute;
      top: -100px;
      z-index: 0;

      .ShieldContainer {
        position: relative;
        height: 454px;
        width: 454px;

        svg {
          position: absolute;
          height: 454px;
          width: 454px;
          opacity: 0.05;
        }

        .TextShield {
          height: 384.27px;
          width: 380.29px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          animation: rotate 60s infinite linear;
        }
      }
    }
  }
}

.OverflowChange {
  overflow: visible;
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
