.Container {
  position: relative;
  min-height: 100vh;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(213.95deg, #775D00 23.94%, #FFFDDC 97.76%);
    opacity: 0.1;
    z-index: -1;
  }

  .InnerContainer {
    display: flex;
    justify-content: center;
    margin: auto;
    padding-top: 75px;
    padding-bottom: 83px;
    position: relative;
    gap: 80px;

    .ShareContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 24px 14px;
      width: 48px;
      height: 164px;
      position: relative;
      gap: 28px;
      background: #f4f2ea;
      border: none;
      border-radius: 40px;
      z-index: 1;

      svg {
        height: 20px;
        width: 20px;
        cursor: pointer;
      }

      svg:not(:first-child) {
        g {
          path {
            fill: #715800;
            opacity: 0.5;
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        background: linear-gradient(217.98deg, #715800 -29.02%, #FCF9CE 123.25%);
        z-index: -1;
        border-radius: inherit;
      }

      &::after {
        content: "";
        position: absolute;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background: inherit;
        z-index: -1;
        border-radius: inherit;
      }
    }

    .EmptyBlog {
      width: 712px;
    }

    .BlogContainer {
      width: 712px;

      .BackButton {
        display: flex;
        align-items: center;
        gap: 10px;
        background: transparent;
        border: none;
        font-weight: 500;
        font-size: 27px;
        line-height: 34px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #715800;
        text-decoration: none;
        cursor: pointer;

        svg {
          transform: rotate(180deg);
        }
      }

      .Title {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 45px;
        line-height: 105%;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #0A1221;
      }

      .Subtitle {
        margin-bottom: 28px;
        font-family: Lobster;
        font-weight: 400;
        font-size: 19px;
        line-height: 25px;
        color: #0E0E0F;
        opacity: 0.7;
      }

      img {
        width: 712px;
        height: 358px;
        object-fit: cover;
        border-radius: 20px;
      }

      .Content {
        font-weight: 400;
        font-size: 19px;
        line-height: 25px;
        color: #0E0E0F;

        h1 {
          line-height: normal;
        }

        a {
          text-decoration: none;
        }

        img {
          width: 100%;
          border-radius: none;
        }
      }
    }

    .RightContainer {
      .ReadMore {
        margin-bottom: 28px;
        height: 48px;
        font-weight: 500;
        font-size: 27px;
        line-height: 34px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #0A1221;
        border-bottom: 3px solid #715800;
      }

      .TopCardContainer {
        display: flex;
        flex-direction: column;
        gap: 28px;

        .ToBlog {
          width: 280px;
          height: 290px;
          position: relative;
          background: #f1eee6;
          border: none;
          border-radius: 18px;
          cursor: pointer;
          text-decoration: none;
          z-index: 1;

          &::before {
            content: "";
            position: absolute;
            top: -3px;
            left: -3px;
            right: -3px;
            bottom: -3px;
            background: linear-gradient(217.98deg, #715800 -29.02%, #FCF9CE 123.25%);
            z-index: -1;
            border-radius: inherit;
          }

          &::after {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            background: inherit;
            z-index: -1;
            border-radius: inherit;
          }
        }
      }
    }
  }

  .Highlight {
    padding-top: 423px;

    .HighlightImage {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 358px;
      object-fit: cover;
    }
  }

  @media (max-width: 1280px) {
    .InnerContainer {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      padding-left: 20px;
      padding-right: 20px;

      .ShareWrapper {
        display: flex;
        width: 712px;

        .ShareContainer {
          flex-direction: row;
          width: auto;
          height: 48px;
          gap: 20px;
          align-self: flex-start;

          svg {
            height: 16px;
            width: 16px;
          }

          svg:not(:first-child) {
            g {
              path {
                fill: #715800;
                opacity: 0.5;
              }
            }
          }
        }
      }

      .RightContainer {
        width: 100%;
      }

      .RightContainer {
        .TopCardContainer {
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 20px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .InnerContainer {
      .BlogContainer,
      .RightContainer {
        width: 100%;
      }

      .ShareWrapper {
        display: flex;
        width: 100%;
      }

      .BlogContainer {
        .BackButton {
          font-size: 24px;
        }

        .Title {
          font-weight: 700;
          font-size: 30px;
          line-height: 105%;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          color: #0A1221;
        }

        .Subtitle {
          margin-bottom: 24px;
          font-family: Lobster;
          font-weight: 400;
          font-size: 19px;
          line-height: 25px;
          color: #0E0E0F;
          opacity: 0.7;
        }

        img {
          width: 100%;
          height: auto;
        }

        .Content {
          font-weight: 400;
          font-size: 14px;
          line-height: 25px;
          color: #0E0E0F;
        }
      }
    }
  }
}
