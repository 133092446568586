.Container {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(213.95deg, rgba(119, 93, 0, 0.1) 23.94%, rgba(255, 253, 220, 0.1) 97.76%);
    z-index: -1;
  }

  .ContentContainer {
    display: flex;
    justify-content: center;
    padding-top: 70px;
    gap: 80px;
    position: relative;

    .svg {
      width: 260px;
      height: 150px;
    }

    .TextContainer {
      width: 720px;

      .TickContainer {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 18px;
        gap: 20px;

        svg {
          height: 24px;
          width: 24px;
        }
      }

      .NumContainer {
        display: flex;
        margin-bottom: 18px;
        margin-top: 18px;
        gap: 20px;

        .NumHead {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #C0B762;
          font-weight: 500;
          font-size: 15px;
          color: #FFFFFF;
          flex-shrink: 0;
        }
      }

      .Title {
        margin-bottom: 28px;
        font-weight: 700;
        font-size: 45px;
        line-height: 105%;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #0A1221;
      }

      div {
        font-weight: 400;
        font-size: 19px;
        line-height: 25px;
        color: #0E0E0F;
      }

      .Item {
        margin-top: 28px;
        margin-bottom: 28px;
        font-weight: 500;
        font-size: 27px;
        line-height: 34px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #715800;
      }
    }
  }

  @media (max-width: 1200px) {
    .ContentContainer {
      flex-wrap: wrap;
      gap: 40px;
    }

    .svg {
      width: 200px;
      height: 120px;
    }

    .TextContainer {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .ContentContainer {
      padding-top: 40px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .TextContainer {
      .Title {
        font-size: 35px;
      }

      div {
        font-size: 16px;
        line-height: 22px;
      }

      .Item {
        font-size: 22px;
      }

      .TickContainer,
      .NumContainer {
        justify-content: flex-start;
      }

      .NumHead {
        width: 20px;
        height: 20px;
        font-size: 12px;
      }
    }
  }

  @media (max-width: 480px) {
    .TextContainer {
      .Title {
        font-size: 30px;
      }

      div {
        font-size: 15px;
        line-height: 20px;
      }

      .Item {
        font-size: 20px;
      }

      .TickContainer,
      .NumContainer {
        gap: 10px;
      }

      .NumHead {
        width: 16px;
        height: 16px;
        font-size: 10px;
      }
    }
  }
}


