.HomeTop {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .InnerContainer {
    position: relative;
    height: 641px;

    .ImageContainer {
      position: relative;
      left: 5%;

      .HomeBackground {
        position: absolute;
        left: 0;
        top: 0;
      }

      .Shield {
        position: absolute;
      }
    }

    .HomeRight {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: auto;
      margin-right: 10%;
      width: 55%;

      .Title {
        margin-right: 50px;
        font-weight: 500;
        font-size: 90px;
        line-height: 112%;
        color: #FFFFFF;
        opacity: 0.9;
        text-align: end;
      }

      .Subtitle {
        font-family: Lobster;
        font-weight: 400;
        font-size: 90px;
        line-height: 123px;
        color: #C0B762;
      }

      .ToServiceButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        width: 213px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: transparent;
        color: #FCF9CE;
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        border: none;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 1280px) {
    .InnerContainer {
      .ImageContainer {
        width: 80%;
        left: 0;

        img {
          display: none;
        }

        .Shield {
          top: 100px !important;
          left: 100px !important;
        }
      }

      .HomeRight {
        width: 90%;
      }
    }
  }

  @media (max-width: 768px) {
    .InnerContainer {
      .ImageContainer {
        .Shield {
          display: none !important;
        }
      }

      .HomeRight {
        width: auto;

        .Title {
          font-size: 40px;
          line-height: 58px;
        }

        .Subtitle {
          font-size: 36px;
          line-height: 58px;
        }

        .ToServiceButton {
          margin-top: 30px;
          width: 180px;
          height: 36px;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
