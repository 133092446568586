.Container {
  padding-top: 45px;
  height: 600px;
  position: relative;

  .Subtitle {
    margin-left: 8%;
    font-weight: 400;
    font-size: 35px;
    color: #715800;
    font-family: Lobster;
  }

  .List {
    width: 1043px;
    margin: auto;

    .Item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 90px;
      position: relative;

      a {
        display: flex;
        gap: 45px;
        align-items: center;
        font-weight: 500;
        font-size: 25px;
        letter-spacing: -0.02em;
        color: #0A1221;
        text-decoration: none;
        opacity: 0.9;

        span {
          font-size: 16px;
          font-weight: 700;
          opacity: 0.2;
        }
      }

      div {
        display: flex;
        align-items: center;
      }

      .Divider {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        opacity: 0.2;
        background: #0A1221;
      }
    }
  }

  .HomeConnect {
    position: absolute;
    left: 0;
    bottom: -270px;
  }

  .ServicesDeco {
    position: absolute;
    top: -275px;
    z-index: -1;
  }

  // Media Queries
  @media (max-width: 1200px) {
    .List {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: 768px) {
    .Subtitle {
      font-size: 30px;
    }

    .List {
      .Item {
        a {
          font-size: 22px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .Subtitle {
      font-size: 25px;
    }

    .List {
      .Item {
        a {
          font-size: 18px;
        }
      }
    }
  }
}
