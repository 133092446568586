.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 988px;
  // background: linear-gradient(116.4deg, #0A1221 7.68%, #0E0E0F 80.05%);
  background: linear-gradient(116.4deg, #601ee7 7.68%, #0378ff 80.05%);

  .TitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 85px;
    margin-bottom: 100px;
    width: 624px;

    .Subtitle {
      font-family: Lobster;
      font-size: 35px;
      line-height: 48px;
      text-align: center;
      color: #C3BE70;
    }

    .Title {
      font-weight: 700;
      font-size: 65px;
      line-height: 105%;
      letter-spacing: -0.02em;
      text-align: center;
      color: #FFFFFF;
    }

    .Description {
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      opacity: 0.8;
    }
  }

  .CardContainer {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    a {
      text-decoration: none;
    }
  }

  .Button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 30px;
    margin-top: 32px;
    margin-bottom: 85px;
    width: 263px;
    height: 50px;
    position: relative;
    gap: 10px;
    // background: #0E0E0F;
    background: #1b5cf9;
    border: none;
    border-radius: 40px;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FCF9CE;
    cursor: pointer;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      background: linear-gradient(217.98deg, #715800 -29.02%, #FCF9CE 123.25%);
      z-index: -1;
      border-radius: inherit;
    }

    &::after {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: inherit;
      z-index: -1;
      border-radius: inherit;
    }
  }

  @media (max-width: 1280px) {
    .CardContainer {
      // flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30px;
    }
  }

  @media (max-width: 768px) {
    .TitleContainer {
      width: 100%;
      padding: 0 30px;
      margin-bottom: 50px;
      text-align: center;

      .Subtitle {
        font-size: 24px;
        line-height: 35px;
      }

      .Title {
        font-size: 30px;
      }

      .Description {
        font-size: 16px;
        line-height: 21px;
      }
    }

    .CardContainer {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  }
}
