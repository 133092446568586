.Container {
  position: relative;
  height: 848px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(213.95deg, #775D00 23.94%, #FFFDDC 97.76%);
    opacity: 0.1;
    z-index: -1;
  }

  .InnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 720px;
    margin: auto;
    padding-top: 72px;

    .Subtitle {
      font-family: Lobster;
      font-weight: 400;
      font-size: 35px;
      line-height: 48px;
      color: #715800;
    }

    .Title {
      font-weight: 700;
      font-size: 65px;
      line-height: 105%;
      letter-spacing: -0.02em;
      color: #0A1221;
      text-align: center;
    }

    form {
      margin-top: 50px;
      width: 100%;

      .TwoInputContainer {
        display: flex;
        gap: 10px;

        .InputContainer {
          width: 50%;
        }
      }

      .InputContainer {
        input {
          margin-top: 12px;
          padding-left: 20px;
          width: 100%;
          height: 54px;
          border: 1px solid;
          border-image-slice: 1;
          border-image-source: linear-gradient(217.98deg, rgba(113, 88, 0, 0.4) -29.02%, rgba(252, 249, 206, 0.4) 123.25%);
          font-weight: 400;
          font-size: 16px;
          line-height: 130%;
          color: #0E0E0F;
          opacity: 0.5;
        }

        .Errors {
          color: red;
        }
      }

      .MessageContainer {
        textarea {
          margin-top: 12px;
          padding-left: 20px;
          width: 100%;
          height: 124px;
          vertical-align: text-top;
          border: 1px solid;
          border-image-slice: 1;
          border-image-source: linear-gradient(217.98deg, rgba(113, 88, 0, 0.4) -29.02%, rgba(252, 249, 206, 0.4) 123.25%);
          text-align: start;
          font-weight: 400;
          font-size: 16px;
          line-height: 130%;
          color: #0E0E0F;
          opacity: 0.5;
        }

        .Errors {
          color: red;
        }
      }

      .SubmitButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 20px;
        margin-top: 32px;
        width: 238px;
        height: 54px;
        position: relative;
        gap: 10px;
        background: #f7f5ee;
        color: #775D00;
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.03em;
        border: none;
        border-radius: 40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        cursor: pointer;

        &::before {
          content: "";
          position: absolute;
          top: -3px;
          left: -3px;
          right: -3px;
          bottom: -3px;
          background: linear-gradient(217.98deg, #715800 -29.02%, #FCF9CE 123.25%);
          z-index: -1;
          border-radius: inherit;
        }

        &::after {
          content: "";
          position: absolute;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          background: inherit;
          z-index: -1;
          border-radius: inherit;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .Container {
    height: auto;
    padding: 20px;

    .InnerContainer {
      width: 100%;

      .Title {
        font-size: 40px;
      }

      form {
        .TwoInputContainer {
          flex-direction: column;

          .InputContainer {
            width: 100%;
          }
        }

        .SubmitButton {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .Container {
    .InnerContainer {
      .Title {
        font-size: 30px;
      }
    }
  }
}
