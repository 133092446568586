footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 460px;
  width: 100%;
  position: relative;
  color: #FFFFFF;

  .LogoWithName {
    height: 155px;
    margin-top: 32px;
  }

  .InfoContainer {
    display: flex;
    align-items: center;
    gap: 40px;
    position: relative;

    .InfoTitle {
      font-size: 20px;
      font-weight: 700;
    }

    .InfoInnerContainer {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 18px;
      font-weight: 300;
    }

    .Divider {
      position: absolute;
      bottom: -30px;
      width: 100%;
      height: 1px;
      background: linear-gradient(217.98deg, #715800 -29.02%, #FCF9CE 123.25%);
    }
  }

  .IconContainer {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 20px;

    svg {
      cursor: pointer;
    }
  }

  .BottomFooter {
    color: #D9D9D9;
    font-weight: 300;
    font-size: 14px;

    span {
      font-weight: 500;
    }
  }

  .FooterBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: linear-gradient(116.4deg, #0A1221 7.68%, #0E0E0F 80.05%);
    background: linear-gradient(116.4deg, #601ee7 7.68%, #0378ff 80.05%);
    z-index: -1;
  }

  @media (max-width: 1100px) and (min-width: 768px) {
    padding: 20px;

    .InfoContainer {
      flex-wrap: wrap;
      gap: 20px;

      .Divider {
        bottom: -15px;
        max-width: 100% !important;
      }
    }

    .InfoContainer > * {
      flex-basis: 100%;
      max-width: 100%;
    }

    .InfoContainer > :nth-child(1) {
      flex-basis: 100%;
      max-width: 100%;
    }

    .InfoContainer > :nth-child(2),
    .InfoContainer > :nth-child(3) {
      flex-basis: 50%;
      max-width: calc(50% - 20px);
    }

    .InfoContainer > :nth-child(4),
    .InfoContainer > :nth-child(5) {
      flex-basis: 50%;
      max-width: calc(50% - 20px);
    }
  }

  @media (max-width: 768px) {
    padding: 20px;

    .LogoWithName {
      height: 100px;
      margin-top: 0;
    }

    .InfoContainer {
      flex-wrap: wrap;
      gap: 20px;

      .InfoTitle {
        font-size: 18px;
      }

      .InfoInnerContainer {
        gap: 8px;
        font-size: 16px;
      }

      .Divider {
        bottom: -15px;
      }
    }

    .IconContainer {
      margin-top: 10px;
    }

    .BottomFooter {
      font-size: 12px;
    }
  }

  @media (max-width: 480px) {
    .InfoContainer {
      justify-content: center;
      text-align: center;
    }

    .IconContainer {
      justify-content: center;
    }
  }
}


