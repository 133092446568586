.AboutContainer {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  .InnerContainer {
    padding-left: 8%;
    padding-right: 8%;
    position: relative;

    .TitleContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .Title {
        margin-top: 100px;
        font-family: 'Parisienne', cursive;
        font-weight: 400;
        font-size: 70px;
        line-height: 112%;
        color: #FFFFFF;
        text-align: center;
        opacity: 0.9;
        z-index: 1;
      }

      .Subtitle1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 24px;
        width: 146px;
        height: 46px;
        position: relative;
        // background: #0E0E0F;
        background: #1b5cf9;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: 0.05em;
        color: #FCF9CE;
        border: none;
        border-radius: 40px;
        z-index: 0;

        &::before {
          content: "";
          position: absolute;
          top: -3px;
          left: -3px;
          right: -3px;
          bottom: -3px;
          background: linear-gradient(217.98deg, #715800 -29.02%, #FCF9CE 123.25%);
          z-index: -1;
          border-radius: inherit;
        }

        &::after {
          content: "";
          position: absolute;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          background: inherit;
          z-index: -1;
          border-radius: inherit;
        }
      }

      .Subtitle2 {
        margin-top: 40px;
        font-weight: 500;
        font-size: 45px;
        line-height: 105%;
        letter-spacing: -0.02em;
        color: #C3BE70;
        text-align: center;
      }
    }

    .InfoContainer {
      display: flex;
      justify-content: space-between;
      margin-top: 160px;

      .InfoLeftContainer {
        display: flex;
        gap: 60px;

        .InfoItem {
          width: 160px;
          color: #FFFFFF;
          font-weight: 400;


          .InfoNum {
            opacity: 0.9;
            font-size: 40px;
            line-height: 112%;
          }

          .InfoText {
            opacity: 0.3;
            font-size: 16px;
            line-height: 130%;
          }
        }
      }

      .ToContactButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        width: 213px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: transparent;
        color: #FCF9CE;
        font-weight: 500;
        font-size: 17px;
        line-height: 22px;
        border: none;
        cursor: pointer;
      }
    }

    .AboutDeco {
      position: absolute;
      left: 0;
      top: 380px;
    }
  }

  @media (max-width: 1280px) {
    .InnerContainer {
      .InfoContainer {
        margin-top: 100px;
        .InfoLeftContainer {
          gap: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .InnerContainer {
      padding-left: 4%;
      padding-right: 4%;

      .TitleContainer {
        .Title {
          margin-top: 40px;
          font-size: 64px;
        }

        .Subtitle1 {
          width: 130px;
          height: 38px;
          font-size: 14px;
        }

        .Subtitle2 {
          margin-top: 30px;
          font-size: 24px;
        }
      }

      .InfoContainer {
        flex-direction: column;
        align-items: center;
        margin-top: 60px;

        .InfoLeftContainer {
          gap: 10px;

          .InfoItem {
            width: 90px;

            .InfoNum {
              font-size: 28px;
            }

            .InfoText {
              font-size: 14px;
            }
          }
        }

        .ToContactButton {
          margin-top: 30px;
          width: 180px;
          height: 36px;
          font-size: 14px;
          line-height: 18px;
        }
      }

      .AboutDeco {
        display: none;
      }
    }
  }
}
